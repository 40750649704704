import React from 'react'

import RedLine from '@c/red-line'

import useViewport from '@h/use-viewport'
import AddressCard from '@c/address-card'
import Slider from '@c/slider-flickity'

const Info = ({
  addressTitle,
  openText,
  closedText,
  mapLinkText,
  addresses
}) => {
  const { width } = useViewport()
  return (
    <div className="container mt-60 mt-lg-80 ta-center">
      <div className="row align-items-end">
        <div className="col-12 col-lg-10 offset-lg-1">
          <div className="border-box u-shadow bg-white p-16 pb-32 p-lg-80">
            <h3>{addressTitle}</h3>

            <RedLine className="mt-32" />
            <Slider
              options={{
                wrapAround: false,
                draggable: true,
                pageDots: width <= 340,
                prevNextButtons: width > 340
              }}
            >
              {addresses.map((address, key) => (
                <AddressCard
                  key={key}
                  {...address}
                  openText={openText}
                  closedText={closedText}
                  mapLinkText={mapLinkText}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info
