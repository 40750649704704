import React, { useState } from 'react'
import FlexibleLink from '@c/common/FlexibleLink'
import { DateTime, Interval } from 'luxon'
import useInterval from '@h/interval'
import Icon from '@c/icon'
import './index.css'

function AddressCard({
  address,
  city,
  phoneNumber,
  timezone,
  workingHoursStart,
  workingHoursEnd,
  openText,
  closedText,
  mapLinkText,
  mapLinkUrl
}) {
  const zone = timezone
  const [currentTime, setCurrentTime] = useState(DateTime.local().setZone(zone))

  const openTime = DateTime.fromFormat(workingHoursStart, 'hh:mm a')
  const closeTime = DateTime.fromFormat(workingHoursEnd, 'hh:mm a')
  const isWeekend = ['Sat', 'Sun'].includes(currentTime.toFormat('ccc'))
  const isWorkingHour = Interval.fromDateTimes(openTime, closeTime).contains(
    currentTime
  )

  useInterval(() => {
    setCurrentTime(DateTime.local().setZone(zone))
  }, 1000)
  return (
    <div className="col-12">
      <address className="mt-32 h5 fs-normal">{address}</address>

      <p className="h5 fw-head-bold c-red-dark mt-32">{city}</p>
      <p className="h5 mt-4">
        <a
          className="link-hover"
          href={`tel:${phoneNumber?.replace(/ /g, '')}`}
        >
          {phoneNumber}
        </a>
      </p>

      <div className="mt-32">
        <FlexibleLink
          title={
            <>
              {mapLinkText}
              <Icon className="ml-8 c-red-dark" />
            </>
          }
          url={mapLinkUrl}
          target={'_blank'}
          className="link-hover-red ml-24"
        />
      </div>

      <div className="mt-16">
        <Icon name="clock" />
      </div>

      <div className="mt-16">
        <small className="ff-head fs-small">
          {currentTime.toFormat('hh:mm a')}{' '}
          {!isWeekend && isWorkingHour ? openText : closedText}
        </small>
        <br className="d-block d-lg-none" />
        <small className="ff-head fs-small c-dark-60 ml-lg-24">
          {openTime.toFormat('hh:mm a')} {closeTime.toFormat('hh:mm a')}
        </small>
      </div>
    </div>
  )
}

export default AddressCard
