import DatoCMSModel from './DatoCMSModel'

// Bazı sayfalar için ayrı birer modelimiz yok. Bunun yerine bu sayfaların alanlarını
// başka modellere gömüyoruz. Örneğin /contact sayfası Site Settings modeline gömülü.

// Diğer modellerde olduğu gibi bu modellerde "locale" diye bir alan yok.
// Bu yüzden "locale" özelliğini constructor fonsksiyonuna biz kendimiz veriyoruz.

// Yine bu "modeller" kendi başlarına birer model olmadıkları için _allXlocales
// şeklinde alanları da yok. Bu yüzden de _allSlugLocales özelliğini kendimiz
// ekliyoruz.
export default class DatoCmsEmbeddedModel extends DatoCMSModel {
  constructor(data, { locale, slugLocales }) {
    super(data)

    this.data.locale = locale
    this.data._allSlugLocales = slugLocales
  }
}
