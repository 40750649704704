import { useState, useEffect } from 'react'
/**
 * Get reactive window inner width.
 * Yoinked from:
 * https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/
 * @returns width
 */
const useViewport = () => {
  if (typeof window !== 'undefined') {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth)
      window.addEventListener('resize', handleWindowResize)
      return () => window.removeEventListener('resize', handleWindowResize)
    }, [])
    return { width }
  }

  // Return the width so we can use it in our components
  return { width: 0 }
}
export default useViewport
