import React from 'react'
import { graphql } from 'gatsby'
import ContactPage from '../../models/ContactPage'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'

import Info from '@v/contact/info'

import './index.css'

const Page = ({ data }) => {
  const { page } = data
  const {
    contactPage: [contactPage = {}],
    locale
  } = page
  const { title } = contactPage
  const slugLocales = page._allContactPageLocales.map(pageLocale => ({
    locale: pageLocale.locale,
    value: pageLocale.value[0]?.slug
  }))
  const model = new ContactPage(contactPage, { locale, slugLocales })

  return (
    <Layout
      mainClass="page-contact"
      locale={locale}
      seo={{ ...model.getSeoComponentProps() }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      {/* header*/}
      <div className="container ta-center">
        <PageHeader
          topTitle={contactPage.sloganSmall}
          title={contactPage.sloganBig}
        />
        <div className="row mt-32">
          <div className="col-12 col-lg-6 offset-lg-3">
            <p>{contactPage.descriptionSmall}</p>
          </div>
        </div>
      </div>

      <Info
        addressTitle={contactPage.addressTitle}
        address={contactPage.address}
        city={contactPage.city}
        phoneNumber={contactPage.phoneNumber}
        timezone={contactPage.timezone}
        workingHoursStart={contactPage.workingHoursStart}
        workingHoursEnd={contactPage.workingHoursEnd}
        openText={contactPage.openText}
        closedText={contactPage.closedText}
        mapLinkText={contactPage.mapLinkText}
        mapLinkUrl={contactPage.mapLinkUrl}
        addresses={page.addresses}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ContactPageQuery($locale: String) {
    page: datoCmsSiteSetting(locale: { eq: $locale }) {
      id
      locale

      _allContactPageLocales {
        locale
        value {
          slug
        }
      }

      contactPage {
        title
        sloganSmall
        sloganBig
        descriptionSmall
        addressTitle
        openText
        closedText
        mapLinkText
        metaTags {
          ...MetaTags
        }
      }
      addresses {
        address
        city
        phoneNumber
        timezone
        workingHoursStart
        workingHoursEnd
        mapLinkUrl
      }
    }
  }
`
